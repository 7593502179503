import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import scheduleApolloClient from '@/lib/appsync/schedule';

import completePrePostTasqJob from '@/graphql/schedule/mutations/completePrePostTasqJob.graphql';

import getScheduleRulesResponse from '@/graphql/userSettings/queries/getScheduleRulesResponse.graphql';



@Module({
  dynamic: true,
  namespaced: true,
  name: 'workspaceModule',
  store,
})
class WorkspaceModule extends VuexModule {
//   availableSignals = getConfigEnv('SIGNALS_ARRAY');

  activeWorkspaces: any[] = []

  activeWorkspace: {}|null|undefined = null;

  @Mutation
  resetWorkspaces(): void {
    this.activeWorkspaces = [];
  }

  @Mutation
  setWorkspaces(workspaces): void {
    this.activeWorkspaces = workspaces;
  }

  @Mutation
  setActiveWorkspace(id: string): void {
    this.activeWorkspace = this.activeWorkspaces.find((i) => i.id === id);
  }




  @Action
  async getWorkspaces() {

	var workspaces = [
		{
			id: "1",
			type: "rig_scheduler",
			name: "Rig scheduler",
			icon: "date_range"
		},
		{
			id: "2",
			type: "well_test",
			name: "Well test",
			icon: "date_range"
		}
	  ];
	  this.setWorkspaces(workspaces)

// 	  try {
// 		  const {
// 		  data: {
// 			complete_pre_post_tasq_job: {
// 			  },
// 		  },
// 		  } = await scheduleApolloClient.mutate({
// 		  mutation: completePrePostTasqJob,
// 		  variables: {
// 			  input: {
// 				  TasqID: tasq_id,
// 				  TasqType: tasq_type,
// 				  PredictionID: prediction_id
// 			  },
// 		  },

// 	  } catch (e) {
// 		  console.error(e);
// 		  throw new Error(e);
// 	  }
  }















}










export default getModule(WorkspaceModule);
