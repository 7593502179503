









































































































































































import { Component, Vue } from 'vue-property-decorator';
import workspaceModule from '@/store/modules/workspaceModule';

@Component({
  components: {},
})
export default class WorkspacesNavbar extends Vue {

  dataLoading = false
  selectedWorkspace = ''
  addChartPopupShowing = false
  showContentPopup = false

  showAddChartSelectionPopup = false
  newRigChartDetailsShowing = false
  newWellTestChartDetailsShowing = false



//   postNewRigChart() {

//   }

//   postNewWellTestChart() {

//   }


//   showAddChartPopup() {
// 	this.addChartPopupShowing = true
// 	this.showContentPopup = true

// 	this.showAddChartSelectionPopup = true
// 	this.newRigChartDetailsShowing = false
// 	this.newWellTestChartDetailsShowing = false
//   }


//   closeAddChartPopup() {
// 	this.addChartPopupShowing = false
// 	this.showContentPopup = false

// 	this.showAddChartSelectionPopup = false
// 	this.newRigChartDetailsShowing = false
// 	this.newWellTestChartDetailsShowing = false
 
//   }

//   newRigChartDetailsBackClicked() {
// 	this.showAddChartSelectionPopup = true
// 	this.newRigChartDetailsShowing = false
// 	this.newWellTestChartDetailsShowing = false  
//   }
//   newWellTestChartDetailsBackClicked() {
// 	this.showAddChartSelectionPopup = true
// 	this.newRigChartDetailsShowing = false
// 	this.newWellTestChartDetailsShowing = false  
//   }

//   addNewRigChart() {
// 	this.showAddChartSelectionPopup = false
// 	this.newRigChartDetailsShowing = true
// 	this.newWellTestChartDetailsShowing = false  
//   }

//   addNewWellTestChart() {
// 	this.showAddChartSelectionPopup = false
// 	this.newRigChartDetailsShowing = false
// 	this.newWellTestChartDetailsShowing = true  
//   }


  get workspaces(): any[] {
	  return workspaceModule.activeWorkspaces
  }

  async created() {
	  await workspaceModule.getWorkspaces()
	  workspaceModule.setActiveWorkspace(this.workspaces[0].id);
	  this.selectedWorkspace = this.workspaces[0].name
	  this.$router.push({
		name: 'Workspaces',
		params: {
			id: this.workspaces[0].id,
		},
	  });
  }


  addNewGroup() {

}

  goToWorkspacePage(workspace) {
	this.selectedWorkspace = workspace.name
    this.$router.push({
      name: 'Workspaces',
      params: {
        id: workspace.id,
      },
    });
	workspaceModule.setActiveWorkspace(workspace.id || '');
  }

}
